<template>
  <div>
    <section-banner v-for="(section, index) in sections" :key="index" :model="section"></section-banner>
  </div>
</template>

<script>
import SectionBanner from "@/components/Frontend/SectionBanner";

export default {
  name: 'LabourLaw',
  components: {
    SectionBanner
  },
  data() {
    return {
      sections: [
        {
          Name: "What we do",
          Title: "Labour Law",
          Subtitle: "Finding agreeable working relationships",
          Description: "The relationship between employer and employee can be tricky at the best of times.",
          img: require("@/assets/img/Natalie2.jpg")
        },
        {
          TextColumn: true,
          Decoration: require("@/assets/img/Commas.svg"),
          TextColumn1: ` <p>Work itself is hard enough without the added undercurrents of dissatisfaction or resentment. At NLA we work to understand each point of view, ease friction, and negotiate outcomes that satisfy both sides. <br></p>
            <p>Our workplace solutions include:<br></p>
            <ul>
              <li>Drafting employment agreements, including restraints of trade, policies and confidentiality clauses;</li>
              <li>Representing or assisting employees and employers with disciplinary hearings and proceedings in the CCMA;</li>
              <li>Advice on disciplinary procedures for employers and employees;</li>
              <li>Mediation between employers and employees;</li>
              <li>Advice on labour issue including unfair labour practices and should we not be able to assist, finding you the best person who can.</li>
            </ul>`,
            TextColumn2: "<blockquote>After a labour dispute with an employee we approached NLA Legal Inc for advice. We received fast accurate advice and documentation on how to proceed step by step. Not once were we left not knowing what to do and always got a swift reply and answer. Storage spot would like to thank NLA Legal for the amazing service.”<br><br>- Karen Bryant, Facility Manager, Storage Spot (Pty) Ltd</blockquote>"
        },
        {
          Profiles: true,
          Name: "Talk to Us",
            Bios: [
            {
              Title: "Natalie Lubbe",
              Subtitle: "Director",
              Linkedin: "linkedin.com/in/natalielubbe",
              Email: "natalie@NLAteam.com",
              Tel: "+27 11 704 1563",
              img: require("@/assets/img/Natalie-bio.jpg"),
              left: true,
            },
            {
            Title: "Touche Munyai",
            Subtitle: "Candidate Attorney",
            Linkedin: "linkedin.com/in/avhurengwitouchemunyai",
            Email: "touche@NLAteam.com",
            Tel: "+27 11 704 1563",
            img: require("@/assets/img/Touche-bio.jpg"),
            left: false,
            last: true
          }
          ],
        },
      ]
    }
  },
}
</script>

<style lang="scss" scoped>

</style>